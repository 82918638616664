import {store} from "../App";

const tokenDuration = 6 * 3600 * 1000;
const dataDuration = 24 * 3600 * 1000;

export function saveSeanetVodToken(token) {
    store.dispatch({
        type: "SET_SEANET_VOD_TOKEN",
        token,
        tokenValidUntil: new Date().getTime() + tokenDuration,
    })
}

export function clearSeanetVodToken() {
    store.dispatch({
        type: "CLEAR_SEANET_VOD_TOKEN",
    })
}


export function saveSeanetVodData(data, allLocales) {
    store.dispatch({
        type: "SET_SEANET_VOD_DATA",
        dataValidUntil: new Date().getTime() + dataDuration,
        data,
        allLocales
    })
}

export function clearSeanetVodData() {
    store.dispatch({
        type: "CLEAR_SEANET_VOD_DATA",
    })
}

export function setShowSeanetLocales(showLocales) {
    store.dispatch({
        type: "SET_SHOW_LOCALES",
        showLocales
    })
}

export function setSeanetLocale(seanetLocale) {
    store.dispatch({
        type: "SET_SEANET_LOCALE",
        seanetLocale
    })
}