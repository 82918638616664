import {getFromSeanet} from "../actions/networkActions";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {saveSeanetVodData} from "../actions/seanetVodActions";
import {useSeanetLocale} from "./useSeanetLocale";
import {getSeanetLocaleInfo} from "../utils/vodUtils";

export const useSeanetGenres = (genreId) => {
    const seanetVodData = useSelector(state => state.seanetVod.data);
    const dataValidUntil = useSelector(state => state.seanetVod.dataValidUntil);
    const seanetLocale = useSeanetLocale();
    const [prevSeanetLocale, setPrevSeanetLocale] = useState(seanetLocale);

    useEffect(() => {
        const fetchSeanetVod = async () => {
            const masterData = await getFromSeanet(`${seanetLocale}/master.json`);

            const genres = {};
            const allLocales = new Set();

            for (const entry of masterData.index) {
                //TODO - confirm whether 0 is safe to use with this API
                entry.genres.push({
                    id: 0,
                    name: getSeanetLocaleInfo(seanetLocale).allMoviesGenreTitle
                });
                if (entry.locales.includes(seanetLocale)) {
                    for (const genre of entry.genres) {
                        if(!genres[genre.id]) {
                            genres[genre.id] = {
                                id: genre.id,
                                name: genre.name,
                                movies: []
                            };
                        }

                        genres[genre.id].movies.push({
                            title: entry.title,
                            path: `${entry.path}`,
                            locales: entry.locales,
                            image: entry.preferredCover,
                        });
                    }
                }
                for (const locale of entry.locales) {
                    allLocales.add(locale)
                }
            }
            saveSeanetVodData(genres, allLocales);
        }

        if(new Date().getTime() > dataValidUntil || !seanetVodData) {
            fetchSeanetVod();
        }
        
        if (seanetLocale !== prevSeanetLocale) {
            setPrevSeanetLocale(seanetLocale);
            fetchSeanetVod();
        }
    }, [seanetVodData, genreId, dataValidUntil, seanetLocale, prevSeanetLocale]);

    if(genreId) {
        return seanetVodData?.[genreId];
    }

    return seanetVodData;
};