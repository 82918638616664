import ReactPlayer from "react-player";
import React from "react";
import {getSeanetUrl} from "../../actions/networkActions";
import {PropTypes} from "prop-types";

const MoviePlayer = ({location}, context) => {

    if (!location || !location.state) {
        return <p>
            {context.t('error_fetching_content')}
        </p>
    }

    const {
        movieId,
        mediaPath,
        token,
        title
    } = location.state;

    return (
        <div className="grid-x grid-padding-x pb-16">
            <div className="cell medium-12">
                <h1>{title}</h1>
                <ReactPlayer
                    id="reactPlayerTag"
                    className="react-player"
                    width="100%"
                    height="100%"
                    url={ getSeanetUrl() + "v/hls/" + movieId + "/" + mediaPath + "/index.m3u8" }
                    playsinline
                    autoPlay
                    controls
                    playing
                    pip={false}
                    config={{
                         file: {
                             hlsOptions: {
                                 xhrSetup: function(xhr) {
                                     xhr.setRequestHeader('Authorization', "Bearer " + token)
                                 }
                             }
                         }
                    }}
                />
            </div>
        </div>
    );
}

MoviePlayer.contextTypes = {
    t: PropTypes.func.isRequired
}

export default MoviePlayer;