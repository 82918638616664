import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {getFromSeanet, getSeanetUrl} from "../../actions/networkActions";
import DetailImage from "../../components/DetailImage";
import {PropTypes} from "prop-types";
import {DefaultButton} from "../../resources/styles";
import browserHistory from "../../utils/history"
import {useSeanetLocale} from "../../hooks/useSeanetLocale";
import {getSeanetLocaleInfo} from "../../utils/vodUtils";

function MovieDetail({match, location}, context) {
    const movieId = match.params.id;
    const token = useSelector(state => state.seanetVod.token);
    const seanetLocale = useSeanetLocale();
    const [movieInfo, setMovieInfo] = useState(null);
    const [playbackLocale, setPlaybackLocale] = useState(seanetLocale);

    const alternativeLocales = location?.state?.locales?.filter(locale => locale !== seanetLocale);

    useEffect(() => {
        const fetchMovieInfo = async () => {
            const response = await getFromSeanet(`${movieId}${playbackLocale === "en_US" ? "" : `/${playbackLocale}`}/metadata.json`);
            if (playbackLocale !== seanetLocale) {
                browserHistory.push(`/seanetvod/moviePlayer`, {
                    movieId,
                    mediaPath: response.media.origin.media,
                    token,
                    title: response.title
                })
            } else {
                setMovieInfo(response);
            }
        }
        fetchMovieInfo();
    }, [movieId, playbackLocale, seanetLocale, token]);

    if (movieInfo === null || token === undefined) {
        return <div>
            loading ...
        </div>
    }

    if (movieInfo === undefined) {
        return <div>
            <p>{context.t('error_fetching_content')}</p>
        </div>
    }

    const {
        title,
        tagline,
        synopses,
        media,
        cast,
        crew,
        path,
        genres
    } = movieInfo;

    const castString = cast.map(actor => actor.name).slice(0,8).join(", ");
    const directors = crew.filter(crew => crew.job === "Director").map(director => director.name);
    const durationMinutes = parseInt(media.origin.runTime / 60) || false;

    return (
        <div>
            <div className="grid-x grid-padding-x">
                <div className="cell small-12 medium-4 large-4 text-center">
                    <DetailImage
                        image={getSeanetUrl() + "sti/" + path + "/thumb/index"}
                    />
                </div>
                <div className="cell small-12 medium-8 large-8 text-left">
                    <h1>
                        {title}
                    </h1>
                    { tagline &&
                        <div className="my-4 text-italic">
                            <p>"{tagline}"</p>
                        </div>
                    }
                    <span>
                        <DefaultButton onClick={() => browserHistory.push(`/seanetvod/moviePlayer`, {
                            movieId,
                            mediaPath: media.origin.media,
                            token,
                            title
                        })}>
                            { context.t('play') } { alternativeLocales?.length > 0 ? getSeanetLocaleInfo(seanetLocale).name : "" }
                        </DefaultButton>
                    </span>
                    { alternativeLocales && alternativeLocales.map((altLocale) =>
                        <span className="ml-2"
                            key={altLocale}
                        >
                            <DefaultButton onClick={() => setPlaybackLocale(altLocale)} >
                                {context.t("play")} {getSeanetLocaleInfo(altLocale).name}
                            </DefaultButton>
                        </span>
                    )}
                    { durationMinutes &&
                        <div className="my-4">
                            <p className="mb-2 font-bold">{context.t('run_time')}:</p>
                            <p>{durationMinutes} mins</p>
                        </div>
                    }
                    <div className="my-4">
                        <p className="mb-2 font-bold">{context.t('summary')}:</p>
                        <p>{synopses.overview}</p>
                    </div>
                    { castString &&
                        <div>
                            <p className="mb-2 font-bold">{context.t('actors')}:</p>
                            <p>{castString}</p>
                        </div>
                    }
                    { directors.length > 0 &&
                        <div className="my-4">
                            <p className="mb-2 font-bold">{context.t('directed_by')}:</p>
                            <p>{directors}</p>
                        </div>
                    }
                    { genres &&
                        <div className="my-4">
                            <p className="mb-2 font-bold">Genres:</p>
                            <p>{genres.map(genre => genre.name).join(", ")}</p>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

MovieDetail.contextTypes = {
    t: PropTypes.func.isRequired
}

export default MovieDetail;