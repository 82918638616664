export const translations = {
  'de': {
    'excursion_update_basket': 'Warenkorb aktualisieren',
    'excursion_error_no_adult_on_excursion': 'Kinder können nur in Begleitung eines Erwachsenen teilnehmen',
    'excursion_remove_from_basket': 'Aus Warenkorb entfernen',
    'excursion_add_to_basket': 'Zum Warenkorb hinzufügen',
    'loading': 'Lädt',
    'excursion_choose_date_label': 'Bitte wählen Sie ein Ausflugsdatum, um Verfügbarkeit und Preise anzuzeigen',
    'please_select': 'Bitte auswählen',
    'excursion_no_available_dates': 'Keine verfügbaren Termine vorhanden',
    'excursion_who_is_traveling': 'Bitte wählen Sie die Teilnehmer aus',
    'total_price': 'Gesamtpreis',
    'excursion_amend_button': 'Ausflug ändern',
    'excursion_cost_label': 'Preis für den Ausflug',
    'basket_header': 'Warenkorb',
    'basket_empty': 'Es befinden sich keine Ausflüge in Ihrem Warenkorb',
    'return_to': 'Zurück zu',
    'home': 'Startseite',
    'cruises_overview_nights_on': 'Nächte auf',
    'day': 'Tag',
    'choose_language_label': 'Sprache wählen',
    'basket': 'Warenkorb',
    'vod-language': 'Filmsprache wählen',
    'logout': 'Ausloggen',
    'itinerary_flight_number': 'Flugnummer',
    'elapsedTime': 'verstrichene Zeit',
    'more_details': 'Mehr Details',
    'duration': 'Dauer',
    'about_the_experience': 'Was Sie Erleben',
    'on_the_way': 'unterwegs',
    'please_note': 'Bitte beachten',
    'book_now': 'Book Now',
    'vod-language-set-to': 'Sie sehen sich derzeit VOD-Titel an, die in verfügbar sind',
    'select_pref_lang': 'Bitte wählen Sie aus den folgenden Optionen Ihre bevorzugte Sprache aus',
    'save': 'Speichern',
    'close': 'Schließen',
    'synopsis': 'Wetterlage',
    'temperature': 'Temperatur',
    'wind': 'Wind',
    'humidity': 'Luftfeuchtigkeit',
    'all_aboard_times': 'Alle an Bord Zeiten',
    'date': 'Datum',
    'arrives': 'Ankunft',
    'all_on_board': 'Alle an Bord',
    'departs': 'Abfahrt',
    'port_information': 'Hafeninformationen',
    'currency': 'Währung',
    'language': 'Sprache',
    'name_of_pier': 'Name des Hafens',
    'ships_local_agent': 'Lokaler Schiffsagent',
    'gangway': 'Steg',
    'no_activity_available': 'Entschuldigung, leider sind für diese Buchung/Unterkunft keine Ausflüge verfügbar',
    'no_activities_available': 'Entschuldigung, leider sind für diese Buchung/Unterkunft keine Ausflüge verfügbar',
    'number_of_persons': 'Number of Persons',
    'time': 'Time',
    'no_timeslots': 'No timeslots are available',
    'please_wait': 'Bitte auswählen',
    'book_not_complete': 'Booking not completed',
    'basket_accept_conditions': 'Allgemeine Geschäftsbedingungen akzeptieren',
    'book_success': 'successfully booked',
    'book_reference_info': 'Buchungsnummer',
    'book_additional_info': 'You can view this booking under ‘My Bookings’ on the Home Page',
    'basket_email_error': 'Bitte geben Sie ihre E-Mail-Adresse ein',
    'basket_email_missmatch': 'Die E-Mail-Adressen stimmen nicht überein',
    'basket_tc_error': 'Bitte akzeptieren Sie die unten aufgeführten Allgemeinen Geschäftsbedingungen',
    'basket_total_cost': 'Gesamtbetrag',
    'basket_contact_details': 'Kontaktdaten',
    'basket_email': 'E-Mail-Adresse',
    'basket_confirm_email': 'Bitte wiederholen Sie Ihre E-Mail-Adresse',
    'basket_mobile_number_label': 'Ihre Mobilfunknummer',
    'basket_mobile_number_tip': 'Bitte geben Sie eine Mobilfunknummer an, damit wir Sie erreichen können',
    'basket_payment_label': 'Zahlungsart',
    'basket_payment_card': 'Kreditkarte',
    'basket_payment_cash': 'Bar vor Ort',
    'basket_payment_tip': 'Bitte wählen Sie eine Zahlungsmethode aus',
    'basket_continue': 'Buchung fortsetzen',
    'confirmation_thank_you_card': 'Vielen Dank für Ihre Reservierung. Ein TUI Mitarbeiter wird Sie vor Ort kontaktieren um die Buchung zu bestätigen und die Kartenzahlung abzuwickeln',
    'confirmation_thank_you_cash': 'Vielen Dank für Ihre Reservierung. Ein TUI Mitarbeiter wird Sie vor Ort kontaktieren um die Buchung zu bestätigen und die Zahlung abzuwickeln',
    'confirmation_header': 'Reservierung getätigt',
    'confirmation_cruise_excursion_text': 'Vielen Dank für die Buchung Ihres Schiffsausfluges. Ihr Kreuzfahrtkonto wird automatisch mit dem angezeigten Preis belastet und die Tickets an Bord zur Verfügung gestellt',
    'confirmation_payment_method': 'Zahlungsart',
    'confirmation_booking_reference': 'Buchungsnummer',
    'confirmation_email': 'Bestätigungsmail',
    'confirmation_mobile_number': 'Mobilfunknummer',
    'confirmation_lead_passenger': 'Hauptpassagier',
    'confirmation_your_excursions': 'Ihre Ausflüge',
    'confirmation_total_cost': 'Gesamtkosten',
    'confirmation_my_excursions_pretext': 'Sie können alle Ihre Reservierungen jederzeit hier einsehen',
    'confirmation_my_excursions': 'Meine Ausflüge',
    'confirmation_excursion_cost': 'Gesamtkosten',
    'no_events': 'Aktuell sind keine weiteren Anlässe geplant',
    'placeholderKeyword': 'Suchbegriff',
    'searchNoResult': 'Leider wurden keine Resultate für Ihre Suche gefunden',
    'technical_data': 'Technische Daten',
    'timetable_start': 'Start',
    'timetable_end': 'Ende',
    'timetable_title': 'Bezeichnung',
    'timetable_deck': 'Deck',
    'timetable_category': 'Category',
    'timetable_location': 'Location',
    'meeting_time': 'Zeit',
    'departure_time': 'Abfahrtszeit',
    'experience': 'Ausflug',
    'bus_or_group': 'Bus-Nr.',
    'meeting_location': 'Treffpunkt',
    'port': 'Hafen',
    'arrival': 'Ankunft',
    'departure': 'Abfahrt',
    'no_whereandwhen': '',
    'whereandwhen_title': '',
    'whereandwhen_deck': '',
    'whereandwhen_times': '',
    'no_restaurants_available': 'Entschuldigung, leider sind für diese Buchung/Unterkunft keine Ausflüge verfügbar',
    'excursion_description_missing': 'Keine Beschreibung vorhanden',
    'excursion_check_availability_button': 'Bitte prüfen Sie hier die Verfügbarkeit',
    'excursion_reserve_button': 'Ausflug reservieren',
    'excursion_available_on_label': 'Buchbare Tage',
    'excursion_price_label': 'Preis',
    'excursion_basket_updated': 'Warenkorb aktualisiert',
    'excursion_go_to_basket_button': 'Weiter zum Warenkorb',
    'excursion_highlights_label': 'Highlights',
    'excursion_included_label': 'Inbegriffen',
    'excursion_important_information_label': 'Wichtig zu wissen',
    'no_excursions_available': 'Entschuldigung, leider sind für diese Buchung/Unterkunft keine Ausflüge verfügbar',
    'no_experiences_available': 'Es sind keine Erlebnisse verfügbar. Bitte wenden Sie sich an Destination Services',
    'my_excursions_header': 'Meine Ausflüge',
    'my_excursions_no_excursions': 'Für diese Buchung wurden keine Ausflüge gebucht',
    'my_excursions_date': 'Datum',
    'my_excursions_title': 'Titel',
    'my_excursions_cost': 'Kosten',
    'tile_my_holiday': 'Mein Urlaub',
    'tile_browse_all_excursions': 'Alle Ausflüge',
    'itineraryTitle': 'Ihr Hotel',
    'tile_itinerary': 'Buchungsübersicht',
    'tile_cruise_excursions': 'Schiffsexkursionen',
    'tile_resort_excursions': 'Hotelexkursionen',
    'tile_my_excursions': 'Meine Ausflüge',
    'no_onboard_bookings': '',
    'confirmed': 'Reservierung getätigt',
    'waitlist': 'Favoriten',
    'cancelled': '',
    'onboard_bookings_title': '',
    'from_time': '',
    'to_time': '',
    'description': '',
    'excursion_code': 'Preis für den Ausflug',
    'musement_view_excursions': 'Meine Ausflüge',
    'hf_login_first_name': 'Bitte geben Sie den Vorname des Reiseteilnehmers ein, welcher die Buchung getätigt hat',
    'hf_login_last_name': 'Bitte geben Sie den Nachnamen des Reiseteilnehmers ein, welcher die Buchung getätigt hat',
    'hf_login_dob': 'Geburtsdatum (dd/mm/yyyy)',
    'login_date_error': 'Bitte Datum eingeben',
    'login_booking_reference_error': 'Bitte geben Sie Ihre Buchungsnummer ein',
    'login_last_name_error': 'Bitte geben Sie den Nachnamen des Reiseteilnehmers ein, welcher die Buchung getätigt hat',
    'reLoginMessageRetina': '',
    'click_here': '',
    'configuration_missing': 'Bestätigungsmail',
    'accessApplication': 'Bitte loggen Sie sich ein',
    'departureDate': 'Reisedatum',
    'bookingReference': 'Buchungsnummer',
    'leadPassengerSurname': 'Nachname',
    'placeholderLastname': 'NACHNAME',
    'login': 'Login',
    'loginFailed': 'Login fehlgeschlagen, bitte die eingegebenen Daten überprüfen',
    'loginFailedRetina': 'Login fehlgeschlagen, bitte die eingegebenen Daten überprüfen',
    'login_success': '',
    'redirect_dashboard': '',
    'redirect_click_dashboard': '',
    'musement_activity_duration': '',
    'musement_activity_price': '',
    'musement_activity_from': '',
    'musement_activity_language': '',
    'musement_activity_languages': '',
    'musement_activity_do_this_because': '',
    'musement_activity_what_to_expect': '',
    'musement_activity_where': '',
    'musement_activity_meeting_point': '',
    'musement_activity_what_is_included': '',
    'musement_activity_what_is_not_included': '',
    'musement_activity_what_to_remember': '',
    'tile_video': '',
    'tile_status': '',
    'tile_schedule': '',
    'total_charges': 'Summe',
    'department': 'Abteilung',
    'charges': 'Preis',
    'no_charges': 'Derzeit keine Einträge',
    'discount': 'Ermässigung',
    'problem_cabin_label': '',
    'problem_category_label': '',
    'problem_description_label': '',
    'problem_description_placeholder': '',
    'problem_submit_label': '',
    'tile_add_problem': '',
    'tile_problem_tracking': '',
    'feedback_thank_you_message': 'Vielen Dank für Ihr Feedback',
    'submitFeedback': 'Feedback absenden',
    'feedback_error_message': 'Das Feedback kann nicht beendet werden, bitte kontaktieren Sie Ihren Ansprechpartner',
    'feedback_termsandconditions_must_be_accepted': '',
    'yes': 'Ja',
    'no': 'Nein',
    'mandatory_answer': '',
    'feedback_please_select': 'Bitte wählen Sie',
    'feedback_textarea_placeholder': 'Maximal 250 Zeichen',
    'genres': 'Genres',
    'error_fetching_content': 'Fehler beim Abrufen des Inhalts',
    'play': 'Abspielen',
    'run_time': 'Laufzeit',
    'summary': 'Beschreibung',
    'actors': 'Schauspieler',
    'directed_by': 'Geleitet von',
    'no_spa_available': 'Entschuldigung, leider sind für diese Buchung/Unterkunft keine Ausflüge verfügbar',
    'spa_justtoknow': 'Just So You Know',
    'spa_price': 'Gesamtpreis',
    'spa_duration': 'Duration',
    'search': 'Suchen',
    'watchlistAdd': 'zu Favoriten hinzufügen',
    'watchlist': 'Favoriten',
    'movies': 'Spielfilme',
    'series': 'Serien',
    'weather-not-available': 'Leider konnte das Wetter nicht abgerufen werden',
    'weather-at-sea': 'Wir sind heute auf See. Bitte versuchen Sie es wieder, wenn wir im Hafen sind',
    'monday': 'Montag',
    'tuesday': 'Dienstag',
    'wednesday': 'Mittwoch',
    'thursday': 'Donnerstag',
    'friday': 'Freitag',
    'saturday': 'Samstag',
    'sunday': 'Sonntag',
    'child': 'Kind',
    'adult': 'Erwachsene',
    'passenger_status_MinimumAge': 'Mindestalter unterschritten',
    'passenger_status_MaximumAge': 'Höchstalter überschritten',
    'passenger_status_AlreadyBooked': 'Bereits reserviert',
    'passenger_status_LastCruiseDay': 'Letzter Tag an Bord',
    'passenger_status_FirstCruiseDay': 'Erster Tag an Bord',
    'passenger_status_NoPriceAvailable': 'Kein Preis verfügbar',
    'passenger_status_TimeOverlapWithOtherBooking': 'Zeitüberschneidung mit anderer Reservierung',
    'passenger_status_TimeOverlapWithOtherCartEntry': 'Zeitüberschneidung mit anderen Reservierung im Warenkorb',
  },
  'options': {
    'plural_rule': 'n != 1',
    'plural_number': '2',
  },
  'en': {
    'excursion_update_basket': 'Update basket',
    'excursion_error_no_adult_on_excursion': 'Adults must accompany Children/Infant',
    'excursion_remove_from_basket': 'Remove from basket',
    'excursion_add_to_basket': 'Add to basket',
    'loading': 'Loading...',
    'excursion_choose_date_label': 'Choose a date to get passenger availability and prices',
    'please_select': 'Please select',
    'excursion_no_available_dates': 'We are sorry. There are no available dates for this excursion for your booking',
    'excursion_who_is_traveling': 'Who\'s travelling on the excursion?',
    'total_price': 'Total price',
    'excursion_amend_button': 'Amend excursion',
    'excursion_cost_label': 'Excursion cost:',
    'basket_header': 'Basket',
    'basket_empty': 'There are no excursions in your basket.',
    'return_to': 'Return to',
    'home': 'Home',
    'cruises_overview_nights_on': 'nights on',
    'day': 'Day',
    'choose_language_label': 'Choose language:',
    'basket': 'Basket',
    'vod-language': 'VOD Language',
    'logout': 'Sign out',
    'itinerary_flight_number': 'Flight no:',
    'elapsedTime': 'Time played',
    'more_details': 'More Details',
    'duration': 'Duration',
    'about_the_experience': 'About the Experience',
    'on_the_way': 'On the way',
    'please_note': 'Please Note',
    'book_now': 'Book Now',
    'vod-language-set-to': 'You are currently viewing VOD titles available in',
    'select_pref_lang': 'Please select your preferred language from the following options',
    'save': 'Save',
    'close': 'Close',
    'synopsis': 'Synopsis',
    'temperature': 'Temperature',
    'wind': 'Wind',
    'humidity': 'Humidity',
    'all_aboard_times': 'All Onboard',
    'port_information': 'Important information',
    'currency': 'Currency',
    'language': 'Language',
    'name_of_pier': 'Name of Pier',
    'ships_local_agent': 'Ship\'s Local Agent',
    'gangway': 'Gangway',
    'date': 'Date',
    'arrives': 'Arrives',
    'all_on_board': 'All Onboard',
    'departs': 'Departs',
    'no_activity_available': 'We are sorry, there are no activities available',
    'no_activities_available': 'No activities available',
    'number_of_persons': 'Number of Persons',
    'time': 'Time',
    'no_timeslots': 'Sorry, there are no timeslots available for the selected day, please contact the relevant ship team.',
    'please_wait': 'Please wait…',
    'book_not_complete': 'The booking cannot be completed, please contact the relevant team',
    'basket_accept_conditions': 'Accept Conditions',
    'book_success': 'successfully booked',
    'book_reference_info': 'Your booking reference number is ',
    'book_additional_info': 'You can view this booking under ‘My Bookings’ on the Home Page.',
    'basket_email_error': 'Please enter your email address',
    'basket_email_missmatch': 'Email addresses must match',
    'basket_tc_error': 'Please accept the Terms & Conditions which are listed below, to continue',
    'basket_total_cost': 'Total cost:',
    'basket_contact_details': 'Contact Details',
    'basket_email': 'Email address:',
    'basket_confirm_email': 'Confirm email address:',
    'basket_mobile_number_label': 'Mobile number:',
    'basket_mobile_number_tip': 'Please enter a mobile number if you have one so we can keep you informed whilst you are on holiday',
    'basket_payment_label': 'Please select your preferred payment method:',
    'basket_payment_card': 'Card',
    'basket_payment_cash': 'Cash',
    'basket_payment_tip': 'This will help when we contact you to complete your reservation',
    'basket_continue': 'Continue',
    'confirmation_thank_you_card': 'Thank you for reserving your excursion. We will phone you 4hrs after your arrival (between 09:00 and 20:00) to confirm your booking and take payment.',
    'confirmation_thank_you_cash': 'Thank you for reserving your excursion. Your TUI rep will make contact once in resort to confirm your booking and take cash payment.',
    'confirmation_header': 'All reserved!',
    'confirmation_cruise_excursion_text': 'Thank you for booking your cruise excursion. Your cruise account will be automatically debited with the price shown, and tickets will be provided on-board your ship.',
    'confirmation_payment_method': 'Payment method:',
    'confirmation_booking_reference': 'Booking reference:',
    'confirmation_email': 'Email:',
    'confirmation_mobile_number': 'Mobile number:',
    'confirmation_lead_passenger': 'Lead passenger:',
    'confirmation_your_excursions': 'Your Excursions',
    'confirmation_total_cost': 'Total cost:',
    'confirmation_my_excursions_pretext': 'You can see all your reservations at any time under',
    'confirmation_my_excursions': 'My Excursions',
    'confirmation_excursion_cost': 'Excursion cost:',
    'no_events': 'No Events found',
    'placeholderKeyword': 'Keyword',
    'searchNoResult': 'Sorry, but we don\'t have any results for your search',
    'technical_data': 'Technical Data',
    'timetable_start': 'Start',
    'timetable_end': 'End',
    'timetable_title': 'Title',
    'timetable_deck': 'Location',
    'timetable_category': 'Category',
    'timetable_location': 'Location Info',
    'meeting_time': 'Meeting Time',
    'departure_time': 'Departure Time ',
    'experience': 'Experience',
    'bus_or_group': 'Bus/Group',
    'meeting_location': 'Meeting Location',
    'port': 'Port',
    'arrival': 'Arrival',
    'departure': 'Departure',
    'no_whereandwhen': 'No Venues found',
    'whereandwhen_title': 'Venue',
    'whereandwhen_deck': 'Deck',
    'whereandwhen_times': 'Times',
    'no_restaurants_available': 'We are sorry, there are no restaurants available',
    'excursion_description_missing': 'Description missing',
    'excursion_check_availability_button': 'Check availability - log in',
    'excursion_reserve_button': 'Reserve excursion',
    'excursion_available_on_label': 'Available on:',
    'excursion_price_label': 'Price: ',
    'excursion_basket_updated': 'Your basket was updated',
    'excursion_go_to_basket_button': 'Continue to basket',
    'excursion_highlights_label': 'Highlights',
    'excursion_included_label': 'Included',
    'excursion_important_information_label': 'Important information',
    'no_excursions_available': 'We are sorry there are no excursions available for this booking/resort',
    'no_experiences_available': 'No experiences available, please contact Destination Services',
    'my_excursions_header': 'My Excursions',
    'my_excursions_no_excursions': 'There are no excursions reserved for this booking.',
    'my_excursions_date': 'Date',
    'my_excursions_title': 'Title',
    'my_excursions_cost': 'Cost',
    'tile_my_holiday': 'My Holiday',
    'tile_browse_all_excursions': 'Browse all excursions',
    'itineraryTitle': 'Your Holiday',
    'tile_itinerary': 'Itinerary',
    'tile_cruise_excursions': 'Cruise Excursions',
    'tile_resort_excursions': 'Resort Excursions',
    'tile_my_excursions': 'My Excursions',
    'no_onboard_bookings': 'There are currently no bookings for your account',
    'confirmed': 'Confirmed',
    'waitlist': 'Waitlist',
    'cancelled': 'Cancelled',
    'onboard_bookings_title': 'Your bookings',
    'from_time': 'From time',
    'to_time': 'To time',
    'description': 'Description',
    'excursion_code': 'Experience code',
    'musement_view_excursions': 'View excursions',
    'hf_login_first_name': 'First name',
    'hf_login_last_name': 'Last name',
    'hf_login_dob': 'Date of birth (dd/mm/yyyy)',
    'login_date_error': 'Please enter date',
    'login_booking_reference_error': 'Please enter your booking reference',
    'login_last_name_error': 'Please enter the surname of the passenger who received the confirmation email',
    'reLoginMessageRetina': 'You are currently logged out of Navigate and need to log in to continue. Please use the link to go to the status/login page.',
    'click_here': 'click here',
    'configuration_missing': 'Configuration missing, please inform the staff about this.',
    'accessApplication': 'Please login',
    'departureDate': 'Departure date',
    'bookingReference': 'Booking Reference',
    'leadPassengerSurname': 'Lead passenger surname',
    'placeholderLastname': 'SURNAME',
    'login': 'Login',
    'loginFailed': 'Login failed, please check your data and try again',
    'loginFailedRetina': 'Login failed, please visit the reception',
    'login_success': 'You are now logged in as: ',
    'redirect_dashboard': 'The page will redirect to navigate dashboard in ',
    'redirect_click_dashboard': 'If the redirect does not happen automatically, please',
    'musement_activity_duration': 'Duration:',
    'musement_activity_price': 'Price:',
    'musement_activity_from': 'from',
    'musement_activity_language': 'Language:',
    'musement_activity_languages': 'Languages:',
    'musement_activity_do_this_because': 'Do This Because',
    'musement_activity_what_to_expect': 'What To Expect',
    'musement_activity_where': 'Where',
    'musement_activity_meeting_point': 'Meeting Point:',
    'musement_activity_what_is_included': 'What\'s Included',
    'musement_activity_what_is_not_included': 'What\'s Not Included',
    'musement_activity_what_to_remember': 'What To Remember',
    'tile_video': 'Muster video',
    'tile_status': 'Muster status',
    'tile_schedule': 'Muster schedule',
    'total_charges': 'Total charges',
    'department': 'Department',
    'charges': 'Charges',
    'no_charges': 'There are currently no charges to your account',
    'discount': 'Discount',
    'problem_cabin_label': 'Cabin:',
    'problem_category_label': 'Problem Category:',
    'problem_description_label': 'Problem Description:',
    'problem_description_placeholder': 'Describe problem here',
    'problem_submit_label': 'Submit Problem',
    'tile_add_problem': 'Report A Problem',
    'tile_problem_tracking': 'Problem Tracking',
    'feedback_thank_you_message': 'Thank you for taking the time to provide feedback.',
    'submitFeedback': 'Submit',
    'feedback_error_message': 'The feedback cannot be completed, please contact the relevant team',
    'feedback_termsandconditions_must_be_accepted': 'Please accept the terms and conditions in order to submit the data.',
    'yes': 'Yes',
    'no': 'No',
    'mandatory_answer': 'This field is mandatory',
    'feedback_please_select': 'Please select',
    'feedback_textarea_placeholder': '250 characters limit',
    'genres': 'Genres',
    'error_fetching_content': 'Error fetching content',
    'play': 'Play',
    'run_time': 'Run Time',
    'summary': 'Summary',
    'actors': 'Actors',
    'directed_by': 'Directed By',
    'no_spa_available': 'We are sorry there are no spa treatments available',
    'spa_justtoknow': 'Just So You Know',
    'spa_price': 'Price',
    'spa_duration': 'Duration',
    'search': 'Search',
    'watchlistAdd': 'Add to watchlist',
    'watchlist': 'Watchlist',
    'movies': 'Movies',
    'series': 'Box Sets',
    'weather-not-available': 'Sorry, weather could not be retrieved',
    'weather-at-sea': 'We are at sea today, please check back when we are next in port.',
    'monday': 'Monday',
    'tuesday': 'Tuesday',
    'wednesday': 'Wednesday',
    'thursday': 'Thursday',
    'friday': 'Friday',
    'saturday': 'Saturday',
    'sunday': 'Sunday',
    'child': 'Child',
    'adult': 'Adult',
    'passenger_status_MinimumAge': 'Person is under minimum age',
    'passenger_status_MaximumAge': 'Person is over maximum age',
    'passenger_status_AlreadyBooked': 'Already booked',
    'passenger_status_LastCruiseDay': 'Last day of the cruise',
    'passenger_status_FirstCruiseDay': 'First day of the cruise',
    'passenger_status_NoPriceAvailable': 'No price available',
    'passenger_status_TimeOverlapWithOtherBooking': 'Time overlap with another booking',
    'passenger_status_TimeOverlapWithOtherCartEntry': 'Time overlap with another planner entry',
  },
}
