const INITIAL_STATE = {
    token: null,
    tokenValidUntil: null,
    data: null,
    dataValidUntil: null,
    showLocales: false,
    seanetLocale: null,
    allLocales: []
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'CLEAR_SEANET_VOD':
            return INITIAL_STATE;

        case 'SET_SEANET_VOD_TOKEN':
            return {  ...state,
                token: action.token,
                tokenValidUntil: action.tokenValidUntil,
            };

        case 'SET_SEANET_VOD_DATA':
            return {  ...state,
                data: action.data,
                dataValidUntil: action.dataValidUntil,
                allLocales: Array.from(action.allLocales)
            };

        case 'SET_SHOW_LOCALES':
            return { ...state,
                showLocales: action.showLocales,
            }

        case 'SET_SEANET_LOCALE':
            return { ...state,
                seanetLocale: action.seanetLocale,
            }

        default:
            return state;
    }
}