import React from 'react';
import {useSeanetGenres} from "../../hooks/useSeanetGenres";
import OverviewImageButton from "../../components/OverviewImageButton";
import {getSeanetUrl} from "../../actions/networkActions";
import OverviewImageButtonPanel from "../../components/OverviewImageButtonPanel";
import {withTheme} from "styled-components";


function Movies(props) {
    const genre = useSeanetGenres(props.match.params.id);

    if(!genre) {
        return (
            <div>
                loading...
            </div>
        )
    }

    return (
        <div>
            <h2 className="margin-horizontal-9375">{genre.name}</h2>
            <OverviewImageButtonPanel>
                {genre.movies.map(movie =>
                    <OverviewImageButton key={movie.path}
                                         link={"/seanetvod/movie/" + movie.path}
                                         linkState={{ locales: movie.locales }}
                                         title={movie.title}
                                         thumbnail={getSeanetUrl() + "sti/" + movie.path + "/thumb/index"}
                                         heightWidthRatio={props.theme.vodMovieHeightWidthRatio || "150%"}
                    />
                )}

            </OverviewImageButtonPanel>
        </div>
    );
}

export default withTheme(Movies);