import React from "react";
import {connect} from "react-redux";
import {PropTypes} from "prop-types";
import If from "../../components/If";
import ImageButton from "../../components/ImageButton";
import {fetchFromHub} from "../../actions/networkActions";
import {getTranslation} from "../../translations/translationUtils";
import OverviewImageButtonPanel from "../../components/OverviewImageButtonPanel";

import LayoutSimple from "../news/LayoutSimple";
import LayoutSingleColumnImage from "../news/LayoutSingleColumnImage";
import LayoutDoubleColumnImageDiffColors from "../news/LayoutDoubleColumnImageDiffColors";
import {setWayfinderProfiles} from "../../actions/wayfinderActions";
import {recordPageview} from "../../utils/gaUtils";

class Overview extends React.Component {

    contentTypeTemplates = {
        LayoutSimple: LayoutSimple,
        LayoutSingleColumn: LayoutSingleColumnImage,
        LayoutDoubleColumn: LayoutDoubleColumnImageDiffColors,
    };

    constructor(props) {
        super(props);
        this.state = {
            category: null,
            keyword: "",
            categories: null,
            filteredCategories: [],
            articles: null,
            filteredArticles: [],
            articlesLoaded: false,
            categoriesLoaded: false,
        };
    }

    componentDidMount() {
        this._updateContent(this.props.match.params.id);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.match.params.id !== this.props.match.params.id) {
            this.setState({
                category: null,
                keyword: "",
                categories: null,
                filteredCategories: [],
                articles: null,
                filteredArticles: [],
                articlesLoaded: false,
                categoriesLoaded: false,
            },() => this._updateContent(this.props.match.params.id));
        }
    }

    _updateContent(id) {
        if (id === undefined) {
            this.props.fetchFromHub(`cms/categories/findByRoot/`, this.updateCategories);
            this.props.fetchFromHub(`cms/content/articles/findByRoot/`, this.updateArticles);
            recordPageview(window.location.pathname + window.location.search, "Category - Content home");
        }
        else {
            const searchParams = new URLSearchParams(window.location.search);
            const previewMode = searchParams.get("previewMode") === "true";
            const wayfinderEnabled = this.props.configuration.hubSettings.enableWayfinder;

            this.props.fetchFromHub( `cms/categories/findById/${id}?previewMode=${previewMode}`, this.updateCategory );
            this.props.fetchFromHub( `cms/categories/findByParentId/${id}?previewMode=${previewMode}`,  this.updateCategories );
            this.props.fetchFromHub(`cms/articles/findByCategoryId/${id}?withWayFinder=${wayfinderEnabled}&previewMode=${previewMode}`, this.updateArticles);
        }
    }

    updateCategory = (category) => {
        recordPageview(window.location.pathname + window.location.search, "Category - " + getTranslation(category.title));
        this.setState({
            category: category,
        })
    };

    updateCategories = (categories) => {
        this.setState({
            categories: categories,
            filteredCategories: categories,
            categoriesLoaded: true,
        })
    };

    updateArticles = (articles) => {
        //update redux store with wayfinderProfiles
        if(this.props.configuration.hubSettings.enableWayfinder) {
            const wayfinderProfileIds = articles
                .filter(article => article.wayfinderProfile)
                .map(article => article.wayfinderProfile.id);

            setWayfinderProfiles(wayfinderProfileIds);
        }

        this.setState({
            articles: articles,
            filteredArticles: articles,
            articlesLoaded: true,
        })
    };

    _handleKeywordChange(keyword) {
        this.setState({
            keyword
        },() => this._handleFilter())
    }

    _handleFilter() {
        const filteredCategories = this._filterElements(this.state.categories);
        const filteredArticles = this._filterElements(this.state.articles);

        this.setState({
            filteredCategories,
            filteredArticles,
        });
    }

    _filterElements(elements) {
        const uppercaseKeyword = this.state.keyword.toUpperCase();
        return elements.filter(element => !this.state.keyword || getTranslation(element.title).toUpperCase().includes(uppercaseKeyword))
    }

    render() {
        if (!this.state.filteredCategories || !this.state.filteredArticles) {
            return false;
        }

        if(!this.state.articlesLoaded || !this.state.categoriesLoaded) {
            return <div>{this.context.t("loading")}</div>
        }

        if (this.state.category && this.state.category.contentType) {

            const ContentTemplate = this.contentTypeTemplates[this.state.category.contentType];

            return (
                <div>
                    <ContentTemplate category={this.state.category} />
                </div>
            )
        }

        return (
            <div>
                    <If test={this.state.category !== null && this.state.category.searchable}>
                        <div className="row">
                            <div className="small-12 medium-12 large-12 columns">
                                <input type="text" value={this.state.keyword}
                                       placeholder={this.context.t("placeholderKeyword")}
                                       onChange={(e) => this._handleKeywordChange(e.target.value)}/>
                            </div>
                        </div>
                    </If>
                    <div className="row">
                        <div>
                            <If test={this.state.filteredCategories.length === 0 && this.state.filteredArticles.length === 0}>
                                <div className="medium-12 columns">
                                    <div className="whale_NoResult">
                                        {this.context.t("searchNoResult")}
                                    </div>
                                </div>
                            </If>
                            <OverviewImageButtonPanel>
                                {
                                    this.state.filteredCategories.map((category) => {

                                        const title = getTranslation(category.title);
                                        const description = getTranslation(category.description);

                                        return (<div key={category.id}
                                                     className="cell">
                                            <ImageButton item={category}
                                                         link={"/content/overview/"+category.id}
                                                         title={title}
                                                         thumbnail={this.props.configuration.hubUrl + "blobs/image?reference=" + category.id + "&width=400"}
                                                         showTitle={category.showTitle}
                                                         showBookNow={category.showBookNow}
                                                         textColor={category.textColour}
                                                         backgroundColor={category.backgroundColour}
                                                         showPreviewTag={!category.published}
                                            />
                                            <If test={description !== null && description.length > 0}>
                                                <div style={{paddingBottom: "26px"}}
    dangerouslySetInnerHTML={{__html: description}}/>
                                            </If>
                                        </div>)
                                    })
                                }

                                {
                                    this.state.filteredArticles.map((article) => {
                                        const title = getTranslation(article.title);
                                        const description = getTranslation(article.overviewDescription);

                                        return (<div key={article.id}
                                                     className="cell">
                                            <ImageButton link={"/content/detail/"+article.id}
                                                         title={title}
                                                         thumbnail={this.props.configuration.hubUrl + "blobs/image?reference=" + article.id + "&width=400"}
                                                         showTitle={article.showTitle}
                                                         showBookNow={article.showBookNow}
                                                         textColor={article.textColour}
                                                         backgroundColor={article.backgroundColour}
                                                         showPreviewTag={!article.published}
                                            />
                                            <If test={description !== null && description.length > 0}>
                                                <div style={{paddingBottom: "26px"}}
    dangerouslySetInnerHTML={{__html: description}}/>
                                            </If>
                                        </div>)
                                    })
                                }
                            </OverviewImageButtonPanel>
                        </div>
                    </div>
            </div>

        )
    }
}


const mapStateToProps = state => {
    return {
        configuration: state.configuration,
    };
};

Overview.contextTypes = {
    t: PropTypes.func.isRequired
};

export default connect(mapStateToProps, {fetchFromHub: fetchFromHub})(Overview);