import React, {useState} from "react";
import {DefaultButton, DefaultSelect, ModalContainer, ModalContent} from "../resources/styles";
import {getSeanetLocaleInfo} from "../utils/vodUtils";
import {PropTypes} from "prop-types";
import {useSelector} from "react-redux";
import {useSeanetLocale} from "../hooks/useSeanetLocale";
import {setSeanetLocale} from "../actions/seanetVodActions";
import styled from "styled-components";

const LocaleModal = styled.div`
    background: ${state => state.theme.bodyBackground};
    color: ${state => state.theme.bodyColor};
    width: 100%;
    padding: 1.5rem;
    button {
      margin-right: 0.5rem;
    }
`

const VodLocaleModal = ({closeModalCallback}, context) => {
    const seanetLocale = useSeanetLocale();
    const allLocales = useSelector(state => state.seanetVod.allLocales);
    const [localeInDropdown, setLocaleInDropdown] = useState(seanetLocale);
    const [saveDisabled, setSaveDisabled] = useState(true);

    const handleLocaleChange = (locale) => {
        setLocaleInDropdown(locale);
        if (locale !== seanetLocale) {
            setSaveDisabled(false);
        } else {
            setSaveDisabled(true);
        }
    }

    return (
        <ModalContainer>
            <ModalContent>
                <LocaleModal>
                    <p>{context.t("vod-language-set-to") }: <strong>{getSeanetLocaleInfo(seanetLocale).name}</strong></p>
                    <p>{context.t("select_pref_lang")}:</p>
                    <DefaultSelect
                        defaultValue={seanetLocale}
                        onChange={(e) => {
                            handleLocaleChange(e.target.value);
                        }}
                    >
                        { allLocales.map((locale) =>
                            <option
                                key={locale}
                                value={locale}
                            >
                                {getSeanetLocaleInfo(locale).name}
                            </option>
                        ) }
                    </DefaultSelect>
                    <div>
                        <DefaultButton
                            disabled={saveDisabled}
                            onClick={() => {
                                setSeanetLocale(localeInDropdown);
                                closeModalCallback();
                            }}
                        >
                            {context.t('save')}
                        </DefaultButton>
                        <DefaultButton onClick={closeModalCallback}>
                            {context.t('close')}
                        </DefaultButton>
                    </div>
                </LocaleModal>
            </ModalContent>
        </ModalContainer>
    )
}

VodLocaleModal.contextTypes = {
    t: PropTypes.func.isRequired
};

export default VodLocaleModal;