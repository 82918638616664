import {useSelector} from "react-redux";

export const useSeanetLocale = () => {
    const mapLangToVodLocale = (lang) => {
        switch (lang) {
            case "en":
                return "en_US"
            case "de":
                return "de_DE"
            default:
                return "en_US"
        }
    }

    const lang = useSelector(state => state.i18nState.lang);
    const defaultLocale = mapLangToVodLocale(lang);
    const locale = useSelector(state => state.seanetVod.seanetLocale);

    return locale ? locale : defaultLocale;
}