export const getSeanetLocaleInfo = (locale) => {
    switch (locale) {
        case "en_US":
            return {
                name: "English",
                allMoviesGenreTitle: "All Movies"
            }
        case "de_DE":
            return {
                name: "Deutsch",
                allMoviesGenreTitle: "Alle Filme"
            }
        default:
            return {
                name: "English",
                allMoviesGenreTitle: "All Movies"
            }
    }
}